import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavMenu from "./NavMenu";

const Header = () => {
    const location = useLocation()
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = (e) => {
        e && e.preventDefault();
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        setShowMenu(false);
    }, [location]);            

    return (
        <>
            <NavMenu show={showMenu} onClose={() => handleMenu(false)} />

            <header>
                <Link to="/">
                    <img loading="lazy" src={require("../../assets/logo.png").default} alt="logo" className="logo" />
                </Link>

                <div className="d-flex flex-row gap-5">
                {
                    isMobile ?
                    <div className="user-select-none">
                        <span className="material-icons-outlined pointer" onClick={handleMenu}>menu</span>
                    </div>
                    :
                    <>
                        <Link to="/how-to-buy" className="option">Cum cumpăr?</Link>   
                        <Link to="/about" className="option">Despre noi.</Link>   
                        <Link to="/contact" className="option">Contact.</Link>   
                    </>
                }
                </div>
            </header>
        </>
    );
}

export default Header;