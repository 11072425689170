import React from 'react'

const Whatsapp =() => {
  return (
    <a href="https://wa.me/+40729158942" className="wapp--button" target="_blank">
        <i class="fa fa-whatsapp"></i>
    </a>
  )
}

export default Whatsapp