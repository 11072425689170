import React from "react";
import { Link } from "react-router-dom";

const NavMenu = ({show, onClose}) => {
    return (
        show ?
        <div className="nav">
            <Link className="nav__logo" to="/">
                <img loading="lazy" src={require("../../assets/logo.png").default} alt="logo" />
            </Link>

            <div className="d-flex flex-column justify-content-start gap-2 m-5 mt-0 mb-0">
                <div className="title">Linkuri utile:</div>
                <Link to="/" className="nav__option">Acasă.</Link>
                <Link to="/how-to-buy" className="nav__option">Cum cumpar?</Link>
                <Link to="/delivery" className="nav__option">Politica livrare.</Link>
                <Link to="/returns" className="nav__option">Politica retur.</Link>
            </div>
            <div className="d-flex flex-column justify-content-start gap-2 m-5 mt-0">
                <div className="title">Firma noastra:</div>
                <Link to="/about" className="nav__option">Despre noi.</Link>
                <Link to="/contact" className="nav__option">Contact.</Link>
            </div>
            <div className="d-flex flex-column justify-content-start gap-4 m-5 mt-0 mb-0">
                <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                    <img
                        width="165" 
                        height="40" 
                        src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SAL.svg" 
                        class="attachment-large size-large wp-image-10879 entered lazyloaded" 
                        alt="" 
                        data-lazy-src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SAL.svg" 
                        data-ll-status="loaded"
                    />
                </a>
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">
                    <img
                        width="165" 
                        height="40" 
                        src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SOL.svg" 
                        class="attachment-large size-large wp-image-10879 entered lazyloaded" 
                        alt="" 
                        data-lazy-src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SOL.svg" 
                        data-ll-status="loaded"
                    />
                </a>
            </div>


            <div className="nav__close material-icons-outlined" onClick={onClose}>
                close
            </div>
        </div>
        :
        <></>
    );
}

export default NavMenu;