import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LogoProject13 from "../../assets/logo-project13.png";

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer className="pb-0">
            <div className={isMobile ? "d-flex flex-column justify-content-between gap-2 p-5 w-100 m-auto" : "d-flex flex-row justify-content-between gap-2 p-5 w-100 m-auto"}>
                <Link to="/" className={isMobile ? "m-auto mb-5" : ""}>
                    <img loading="lazy" src={require("../../assets/logo.png").default} alt="logo" className="logo" />
                </Link>

                <div className="d-flex flex-column justify-content-center gap-5">
                    <div className={isMobile ? "d-flex flex-column justify-content-center gap-5" : "d-flex flex-row justify-content-center gap-5"}>
                        <div className="d-flex flex-column justify-content-start gap-2 m-5 mt-0 mb-0">
                            <div className="title">Linkuri utile:</div>
                            <Link to="/how-to-buy" className="option">Cum cumpăr?</Link>
                            <Link to="/delivery" className="option">Politică livrare.</Link>
                            <Link to="/returns" className="option">Politică retur.</Link>
                        </div>
                        <div className="d-flex flex-column justify-content-start gap-2 m-5 mt-0 mb-0">
                            <div className="title">Firma noastră:</div>
                            <Link to="/about" className="option">Despre noi.</Link>
                            <Link to="/contact" className="option">Contact.</Link>
                        </div>
                        <div className="d-flex flex-column justify-content-start gap-2 m-5 mt-0 mb-0">
                            <div className="title">Detalii comerciale:</div>
                            <div className="option">FANEATA GASS SRL</div>
                            <div className="option">CUI: 30816664</div>
                            <div className="option">J40/8727/2013</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center gap-4 m-5 mt-0 mb-0">
                        <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                            <img
                                width="165"
                                height="40"
                                src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SAL.svg"
                                class="attachment-large size-large wp-image-10879 entered lazyloaded"
                                alt=""
                                data-lazy-src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SAL.svg"
                                data-ll-status="loaded"
                            />
                        </a>
                        <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">
                            <img
                                width="165"
                                height="40"
                                src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SOL.svg"
                                class="attachment-large size-large wp-image-10879 entered lazyloaded"
                                alt=""
                                data-lazy-src="https://butelii-domiciliu.ro/wp-content/uploads/2022/10/SOL.svg"
                                data-ll-status="loaded"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div class="disclaimer p-5">
                <a href="https://ctrl13.ro">
                    <img src={LogoProject13} alt="project13" class="logo" />
                </a>

                <a href="https://project13.ro">project13.ro</a>
            </div>

            <p className="text-left w-100 ps-5 fs-6">© 2023 Faneata GASS. Toate drepturile rezervate.</p>
        </footer>
    );
}

export default Footer;