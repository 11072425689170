import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import { Header, Footer, Loader } from "../common";
import Whatsapp from "../common/Whatsapp";

const Home = lazy(() => delayForClient(import('./Home')));
const About = lazy(() => delayForClient(import("./About")));
const Contact = lazy(() => delayForClient(import("./Contact")));
const Buy = lazy(() => delayForClient(import("./Buy")));
const Delivery = lazy(() => delayForClient(import("./Delivery")));
const Returns = lazy(() => delayForClient(import("./Returns")));

function delayForClient(promise) {
    return new Promise(resolve => {
      setTimeout(resolve, 2500);
    }).then(() => promise);
  }

const Application = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Header />

            <Switch>
                <Route exact path="/how-to-buy">
                    <Buy />
                </Route>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/delivery">
                    <Delivery />
                </Route>
                <Route exact path="/returns">
                    <Returns />
                </Route>
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>

            <Whatsapp />

            <Footer />
        </Suspense>
    );
};

export default Application;